import React, { useContext, useState, useEffect } from "react"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Input,
    Checkbox,
    FormLabel,
    useToast,
    Spinner,
} from "@chakra-ui/react"

import { UserContext } from "~config/user-context"
import { createRoom, addUserToRoom } from "~queries"
import { InsertRoom, Room } from "~models"

const CreateRoomModal: React.FC<{
    isOpen: boolean
    onClose: () => void
    editRoom?: Room
}> = ({ isOpen, onClose, editRoom }) => {
    const toast = useToast()
    const { user } = useContext(UserContext)
    const [roomId, setRoomId] = useState("")
    const [description, setDescription] = useState("")
    const [discordLink, setDiscordLink] = useState("")
    const [isPrivate, setIsPrivate] = useState(false)

    const [isUpdating, setIsUpdating] = useState(false)

    useEffect(() => {
        if (editRoom) {
            setRoomId(editRoom.id)
            setDescription(editRoom.description)
            setDiscordLink(editRoom.discordLink)
            setIsPrivate(editRoom.private)
        }
    }, [])

    const submit = async () => {
        const room: InsertRoom = {
            id: roomId,
            ownerId: user!.id,
            description,
            discordLink,
            participants: [],
            private: isPrivate,
        }
        setIsUpdating(true)
        const result = await createRoom(room)
        if (result.status === "error") {
            toast({
                title: "Room creation failed.",
                description: result.message,
                status: "error",
                isClosable: true,
            })
            setIsUpdating(false)
            return
        }

        const addResult = await addUserToRoom(user!.id, room.id)
        if (addResult.status === "success") {
            toast({
                title: "Room created.",
                description: `We've successfully created room ID: ${roomId}.`,
                status: "success",
                isClosable: true,
            })
        } else {
            toast({
                title: "Room creation failed.",
                description: result.message,
                status: "error",
                isClosable: true,
            })
        }
        setRoomId("")
        setDescription("")
        setDiscordLink("")
        setIsPrivate(false)
        onClose()
        setIsUpdating(false)
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent p="20px">
                <ModalHeader>Creating New Room</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Create your own queue and invite players to join you!
                </ModalBody>
                <FormLabel>Room ID</FormLabel>
                <Input
                    type="text"
                    value={roomId}
                    onChange={event => setRoomId(event.target.value)}
                />

                <FormLabel>Description</FormLabel>
                <Input
                    type="text"
                    value={description}
                    onChange={event => setDescription(event.target.value)}
                />

                <FormLabel>Discord Link</FormLabel>
                <Input
                    type="text"
                    value={discordLink}
                    onChange={event => setDiscordLink(event.target.value)}
                />

                <FormLabel>Private Room</FormLabel>
                <Checkbox
                    isChecked={isPrivate}
                    onChange={event => setIsPrivate(event.target.checked)}
                />

                <ModalFooter>
                    <Button colorScheme="teal" mr={3} onClick={submit}>
                        {isUpdating ? <Spinner /> : "Create"}
                    </Button>
                    <Button variant="ghost" onClick={onClose}>
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default CreateRoomModal
