import React, { useState, useEffect } from "react"
import { Box, Flex, Heading, Button } from "@chakra-ui/react"

import ResponsiveBlock from "~components/shared/responsive-block"
import CreateRoomModal from "~components/shared/create-room-modal"
import RoomCard from "~components/queues/room-card"
import { subscribeToPublicRooms } from "~queries"
import { Room } from "~models"

const IndexPage = () => {
    const [rooms, setRooms] = useState<Room[]>([])
    const [isRoomModalOpen, setRoomModalOpen] = useState(false)

    useEffect(() => {
        const subscription = subscribeToPublicRooms(snapshot => {
            const tmpRooms: Room[] = []
            snapshot.forEach((doc: { data: () => Room }) =>
                tmpRooms.push(doc.data())
            )
            setRooms(tmpRooms)
        })
        return () => subscription()
    }, [])

    return (
        <ResponsiveBlock>
            <Box py="36px">
                <Flex
                    justifyContent="space-between"
                    flexDirection={["column", "row"]}
                >
                    <Heading>See queues below</Heading>
                    <Button onClick={() => setRoomModalOpen(true)}>
                        Create New Room
                    </Button>
                </Flex>
                {rooms.map(room => (
                    <RoomCard key={room.id} room={room} />
                ))}
            </Box>
            <CreateRoomModal
                isOpen={isRoomModalOpen}
                onClose={() => setRoomModalOpen(false)}
            />
        </ResponsiveBlock>
    )
}

export default IndexPage
