import React, { useState, useEffect, useContext } from "react"
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    Box,
    Heading,
    Flex,
    Button,
    Tooltip,
    useToast,
    Link,
    Text,
    Tag,
} from "@chakra-ui/react"
import { BsStarFill, BsController } from "react-icons/bs"

import { UserContext } from "~config/user-context"
import InviteUsersModal from "~components/shared/invite-users-modal"
import { getUsersByIds, addUserToRoom, removeUserFromRoom } from "~queries"
import { User, Room } from "~models"
import EditRoomModal from "../../shared/edit-room-modal"

const RoomCard: React.FC<{ room: Room }> = ({ room }) => {
    const { user: currentUser } = useContext(UserContext)
    const toast = useToast()
    const [users, setUsers] = useState<User[]>([])
    const [editIsOpen, setEditIsOpen] = useState(false)

    const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false)

    useEffect(() => {
        getUsersByIds(room.participants).then(res => {
            if (res.data) setUsers(res.data)
        })
    }, [room])

    const joinRoom = async () => {
        const { status } = await addUserToRoom(currentUser!.id, room.id)

        if (status === "success") {
            toast({
                title: `Added to room ${room.id}`,
                description:
                    "You have successfully been added to the room. You may now see the Discord link for this room.",
                status: "success",
                duration: 4000,
                isClosable: true,
            })
        } else {
            toast({
                title: `Failed to join room.`,
                status: "error",
                duration: 4000,
                isClosable: true,
            })
        }
    }

    const editRoom = () => {
        setEditIsOpen(true)
    }

    const leaveRoom = async () => {
        const success = await removeUserFromRoom(currentUser!.id, room.id)

        if (success) {
            toast({
                title: `Removed from room ${room.id}`,
                description: "You have been removed from the room.",
                status: "success",
                duration: 4000,
                isClosable: true,
            })
        } else {
            toast({
                title: `Failed to leave room.`,
                status: "error",
                duration: 4000,
                isClosable: true,
            })
        }
    }

    const openInviteUserModal = () => {
        setInviteUserModalOpen(true)
    }

    return (
        <>
            <Box
                padding={["20px 0px", "20px"]}
                marginTop="60px"
                borderRadius="30px"
                boxShadow="neu-md"
            >
                <Flex
                    px={["10px", "0px"]}
                    justifyContent={"space-between"}
                    flexDirection={["column", "row"]}
                >
                    <Flex flexDirection="column" pb={[4, 0]}>
                        <Heading fontSize="sm" color="primary">
                            Room ID:
                        </Heading>
                        <Flex>
                            <Heading fontSize="lg" color="teal">
                                {room.id}
                            </Heading>
                            {room.inGame && (
                                <Tag ml={2} bg="rgba(100,100,100,0.2)">
                                    <BsController /> <Box pl={2} />
                                    IN GAME
                                </Tag>
                            )}
                        </Flex>
                    </Flex>
                    <Flex
                        flexDirection="column"
                        alignItems={["flex-start", "flex-end"]}
                        pb={[4, 0]}
                    >
                        <Heading fontSize="sm" color="primary">
                            Players:
                        </Heading>
                        <Heading
                            fontSize="lg"
                            color="teal"
                            pl={4}
                        >{`${room.participants.length} of ${room.maxParticipants}`}</Heading>
                    </Flex>
                </Flex>
                <Table variant="striped" colorScheme="gray">
                    <TableCaption fontStyle="italic">
                        {room.description}
                    </TableCaption>

                    <Thead>
                        <Tr>
                            <Th>Player</Th>
                            <Th>Team</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {users.map(player => (
                            <Tr key={player.id}>
                                <Td>
                                    <Flex align="center">
                                        {room.ownerId === player.id && (
                                            <>
                                                <Tooltip label="Room Owner">
                                                    <span>
                                                        <BsStarFill />
                                                    </span>
                                                </Tooltip>
                                                <Box pr={2} />
                                            </>
                                        )}
                                        {player.username}
                                    </Flex>
                                </Td>
                                <Td>{player.team}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                {room.participants.includes(currentUser!.id) && (
                    <Text color="teal" p={2}>
                        <Box as="span" fontWeight={900}>
                            Voice Chat Link:{" "}
                        </Box>
                        <Tooltip label="Make sure you trust this link before visiting!">
                            <Link
                                isExternal
                                href={room.discordLink}
                                overflowWrap="anywhere"
                            >
                                {room.discordLink}
                            </Link>
                        </Tooltip>
                    </Text>
                )}
                <Flex justifyContent="flex-end" gridGap={2} py={2}>
                    {!room.participants.includes(currentUser!.id) && (
                        <Button color="teal" onClick={joinRoom}>
                            Join Room
                        </Button>
                    )}
                    {room.ownerId === currentUser!.id && (
                        <Button color="teal" onClick={editRoom}>
                            Edit Room
                        </Button>
                    )}
                    {room.participants.includes(currentUser!.id) && (
                        <>
                            <Button color="teal" onClick={openInviteUserModal}>
                                Invite Users
                            </Button>
                            <Button variant="outline" onClick={leaveRoom}>
                                Leave Room
                            </Button>
                        </>
                    )}
                </Flex>
            </Box>
            <InviteUsersModal
                isOpen={inviteUserModalOpen}
                onClose={() => setInviteUserModalOpen(false)}
                room={room}
            />
            <EditRoomModal
                isOpen={editIsOpen}
                onClose={() => setEditIsOpen(false)}
                editRoom={room}
            />
        </>
    )
}

export default RoomCard
